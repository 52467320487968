// Here you can add other styles

.thumbnail-image {
    width:4rem;
    height:4rem;
    min-width: 4rem;
    object-fit:contain;
    background: #ccc;
    border-radius: 5px;
}

.thumbnail-image-user {
    width:4rem;
    height:4rem;
    min-width: 4rem;
    object-fit:contain;
    background: #ccc;
    border-radius: 100%;
}